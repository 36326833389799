<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Good Remittance</h4> 
              </CCol>              
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
              </CCol>              
          </CRow>
        </CCardHeader><br/>
        <CCardBody>

         <CRow>
            <CCol col="12" class="text-left">                   
               <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
            </CCol> 
          </CRow>  

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>

        <div class="table-responsive table scroll-r">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" :current-page="currentPage" :per-page="perPage" :filter="filter"> 

              <template v-slot:cell(createDate)="{ item }"> 
                {{ item.createDate | dateWithTime }}
              </template>

              <template v-slot:cell(actions)="{ item }"> 

                <CButton v-on:click="ActionComplete(item.providerTxnId)" color="primary">Complete</CButton>

              </template>

          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import TransactService from '@/api/TransactService.js';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';

 export default {
    name: "errorremitt",
    components: {
      VueTypeaheadBootstrap,            
    },     
    data() {
      return {
        spinner:false,
        list:[],
        columns : [],
        data : [],
        items:[],             
        fields: [ 
          {
            key: 'createDate',
            label : 'Tr Date',
            sortable: true
          },   
          {
            key: 'movementRefId',
            label : 'Tr Reference',
            sortable: true
          },         
          {
            key: 'senderReference.userId',
            label : 'Sender ID',
            sortable: true
          },          
          {
            key: 'senderCurrency',
            label : 'Currency',
            sortable: true
          },  
          {
            key: 'sendAmount',
            label : 'Amount',
            sortable: true
          },
          {
            key: 'provider',
            label : 'Provider',
            sortable: true
          },  
          {
            key: 'movementStatus',
            label : 'Status',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }                   
        ],         
        currentPage: 1,
        perPage: 10,
        filter: null,
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        }
      },

    created () {        
       this.getRemittaceErrorList("good");          
    },
    methods: { 

      ActionComplete(id){
          var result = confirm("Are you sure want to Complete Transaction ?");
          if (result) { this.completeRemittaceGoodList(id); }
      },

      getFilterData(){          
         this.data  = this.list;
      },      
   
      completeRemittaceGoodList: function(ProTxnId) {
        TransactService.completeRemittaceErrorList(ProTxnId).then(resp => {  //console.log(resp);

            if(resp.message==="SUCCESS"){   alert("Action Complete Success!");             
              this.getRemittaceErrorList("good");
            } else {
              this.msg   =  !resp.data.message ? "Action Complete Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            } 
            setTimeout( () =>  this.seen=false , 5000);    
        }, error => {
          this.spinner = false;
        });
      },         

      getRemittaceErrorList: function(status) {
         TransactService.getRemittaceErrorList(status).then(resp => { //console.log(resp);
            this.items = resp;
         }, error => { this.spinner = false; });
      }      

    }
 }; 

</script>

<style>
    .scroll-r{ overflow-y:scroll; }
</style>